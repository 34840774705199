type ContextErrors = {
    [key: string]: {
        [key: string]: string;
    };
};

export type UseApiErrorsResolver = {
    resolveApiErrors(errors: AhApiError[]): string[];
    extractValidationErrors(errors: SymfonyValidationError[]): Record<string, string>;
};

export type AhApiError = {
    message: string;
    code: number | string;
    id: string;
    name: string;
    quantity: number;
    resolved: boolean;
    key: string;
    level: number;
    messageKey: string;
    meta: {
        parameters: Record<string, string | number>;
    };
    block: boolean;
    blockResubmit: boolean;
};

export type SymfonyValidationError = {
    message: string; // this is actually translation key, not the message
    propertyPath: string; // e.g. "zipCode"
    parameters: Record<string, string>;
};

/**
 *  List of the unsupported/broken backend errors
 */
const contextErrors: ContextErrors = {
    account_login: {
        '0': 'login_no_matching_customer_internal',
    },
};

export function useApiErrorsResolver(context?: string): UseApiErrorsResolver {
    const { t, te } = useI18n();

    /**
     * In some cases, symfony validation error parameters
     * come with additional special characters. We have to remove them.
     *
     * Example:
     *   "meta": {
     *          "parameters": {
     *             "{{ email }}": "<value>"
     *          }
     *   }
     */
    const cleanupParameters = (params: Record<string, string | number>) => {
        const pureParams: Record<string, string | number> = {};
        for (const [key, value] of Object.entries(params)) {
            pureParams[key.replace(/[^a-zA-Z0-9]/g, '')] = value;
        }

        return pureParams;
    };

    const resolveApiErrors = (errors: AhApiError[]) => {
        if (!errors || errors?.length === 0) return [];

        const resolvedErrors = errors
            .map(({ code, messageKey, meta }) => {
                /**
                 * In some cases, parameters errors
                 * comes with additional special characters.
                 * We have to remove them
                 *
                 * Example:
                 *   "meta": {
                 *          "parameters": {
                 *             "{{ email }}": "<value>>"
                 *        }
                 *   }
                 */
                if (meta?.parameters) {
                    meta.parameters = cleanupParameters(meta.parameters);
                }

                let translationKey = messageKey;
                if (!translationKey && typeof code === 'string') {
                    translationKey = code;
                }

                if (translationKey && te(`errors.${translationKey}`)) {
                    return t(`errors.${translationKey}`, { ...meta?.parameters });
                }
                if (context && translationKey && contextErrors[context][translationKey]) {
                    return t(`errors.${contextErrors[context][translationKey]}`, { ...meta?.parameters });
                }

                return null;
            })
            .filter((error): error is string => typeof error === 'string');

        return resolvedErrors.length ? resolvedErrors : [t('errors.message-default')];
    };

    /** create object of invalid fields from backend error struct
     * should result in something like { zipcode: 'This value is not a valid ZIP code'} */
    const extractValidationErrors = (errors: SymfonyValidationError[]) => {
        if (!errors || errors.length === 0) return {};

        return errors?.reduce((acc: Record<string, string>, error) => {
            const pureParams = cleanupParameters(error?.parameters);

            if (error && error.propertyPath) {
                acc[error.propertyPath] = t(`${error.message}`, pureParams);
            }

            return acc;
        }, {});
    };

    return {
        resolveApiErrors,
        extractValidationErrors,
    };
}
